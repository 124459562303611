export const projects = [
  {
    id: '29ac6918-3dda-11ee-be56-0242ac120042',
    imgSrc: 'https://i.postimg.cc/tTKw5bWf/BeMyDate.avif',
    title: 'Be my valentine',
    description: 'The idea of this project was to have fun, be creative and practice my React and TypeScript skills. I designed it like a treasure hunt, where the reciever searches for breadcrumbs to progress. Initially, I made a game plan in Figma but quickly realised it might hinder my goal(to ask someone out) by being too challenging. I therefore simplified the steps, adding quirky features to each page instead, making sure each page challenged me in some way code-wise.',
    techs: ['Typescript', 'HTML5', 'CSS3', 'JavaScript', 'React', 'Styled Components', 'Figma'],
    githubLink: 'https://github.com/JohLeo/cake-crumbs',
    netlifyLink: 'https://bemydate.netlify.app'
  },
  {
    id: '29ac6918-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/gJ8xwHqv/Homeigo-final.png',
    title: 'Homeigo realtor website',
    description: 'For our final project, we collaborated on creating a realtor homepage. Working as a team of three, we undertook the entire project – constructing the database, managing all the functionality, crafting the user interface and design. Our toolkit included a variety of technologies, such as React, Redux, Node.js, and MongoDB. A notable highlight was our integration of a user-friendly map feature along with a search function, elevating the overall user experience.',
    techs: ['HTML5', 'CSS3', 'JavaScript', 'React', 'Redux', 'Express.js', 'Node.js', 'MongoDB', 'Styled Components', 'Mob-Programming'],
    githubLink: 'https://github.com/JohLeo/final-project',
    netlifyLink: 'https://melodic-daffodil-115469.netlify.app'
  },
  {
    id: '29ac6c24-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/sDyhhqL1/Auth-16.png',
    title: 'Authenticator',
    description: 'A pair programming project focused on developing an authenticator system. Our main objectives were to implement secure token-based authentication and ensure the safe storage of user passwords within the database. With an emphasis on security and defensive design, our goal was to create an authenticator solution that prioritizes safety and reliability.',
    techs: ['HTML5', 'CSS3', 'JavaScript', 'React', 'Redux', 'Express.js', 'Node.js', 'Styled Components'],
    githubLink: 'https://github.com/JohLeo/project-auth',
    netlifyLink: 'https://effortless-empanada-ad1e86.netlify.app/'
  },
  {
    id: '029ac6f8a-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/YS4G7HvD/Happy2-v15.png',
    title: 'Happy thoughts',
    description: ['A Twitter-like message app but with more positivity and less Elon Musk. I developed both the backend and frontend, building an API that enables users to fetch and post data to and from the database. This functionality allows you to share happy thoughts on the feed. Utilizing MongoDB on the backend allowed me to delve deeper into setting up endpoints, storing data, and managing user input.'],
    techs: ['HTML5', 'CSS3', 'JavaScript', 'React', 'MongoDB', 'Node.js', 'Express.js'],
    githubLink: 'https://github.com/JohLeo/project-happy-thoughts-api',
    netlifyLink: 'https://happy-mongo-api-jl.netlify.app'
  },
  {
    id: '29ac7124-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/GtRHvTq6/Labyrinth-v12.png',
    title: 'The Labyrinth',
    description: 'A text-based adventure game that allows users to navigate through a maze generated by a backend constructed using React and Redux, alongside a game API from Technigo. Our main focus was on leveraging thunks and Redux to ensure smooth communication with the backend. To add an extra layer of enjoyment, we designed our own images and settings for the project. ',
    techs: ['HTML5', 'CSS3', 'JavaScript', 'React', 'Redux', 'Styled Components', 'Mob-Programming', 'Restful-API'],
    githubLink: 'https://github.com/Nahnahke/project-labyrinth',
    netlifyLink: 'https://project-labyrinth-jhs.netlify.app'
  },
  {
    id: '29ac72b4-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/Wp74Q39b/Todo-v11.png',
    title: 'ToDo app',
    description: 'A React-based todo app utilizing Redux and styled components. This project provided an opportunity to work with and modify the Redux store. The app allows users to easily manage tasks, including adding, deleting, and checking todos.',
    techs: ['HTML5', 'CSS3', 'JavaScript', 'React', 'Redux', 'Styled Components'],
    githubLink: 'https://github.com/JohLeo/project-todos',
    netlifyLink: 'https://thetodo-jl.netlify.app'
  },
  {
    id: '29ac7444-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/c44JZSj7/Ux-Collab-v9.png',
    title: 'Design Hand-off',
    description: 'A collaboration with UX Designer Alice Wennberg, who designed the project and provided a design hand-off at the start of the week, we embarked on creating a landing page for a yoga studio within 7 days. This project not only offered valuable experience in executing a predetermined design but also showcased the effectiveness of a streamlined hand-off process.',
    techs: ['HTML5', 'CSS3', 'JavaScript', 'React', 'Styled Components'],
    githubLink: 'https://github.com/JohLeo/project-design-handoff',
    netlifyLink: 'https://ux-handoff-poweryoga.netlify.app'
  },
  {
    id: '29ac775a-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/yxTWj6Gx/Survey-v6.png',
    title: 'Relaxing survey',
    description: 'A simple survey using controlled form inputs. This project introduced the concept of state in React, and familiarized us with the useState hook and how to show different components depending on one\'s place in a survey. My goal was to create a calm atmosphere, culminating in a soothing breathing exercise for my team amid our studies. ',
    techs: ['HTML', 'CSS', 'JavaScript', 'React'],
    githubLink: 'https://github.com/JohLeo/project-survey',
    netlifyLink: 'https://stress-less-survey.netlify.app'
  },
  {
    id: '29ac78f4-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/W4QDbHrN/Movies-v8.png',
    title: 'Movie Releases',
    description: 'A multi-page web application using React Router and an API from IMDB. It allows users to view a list of latest movie releases and select a movie to read more about. Built as a pair-programming project.',
    techs: ['HTML', 'CSS', 'JavaScript', 'React', 'Mob-programming'],
    githubLink: 'https://github.com/mvfrid/project-movies',
    netlifyLink: 'https://johanna-matilda-project-movies.netlify.app'
  },
  {
    id: '29ac7a66-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/yxwdmm7c/Weather-v3.png',
    title: 'Weather app',
    description: 'A basic weather app with a five day forecast for Stockholm. This project focused on integrating APIs and retrieving external data for our website. It involved collaborative mob programming and provided foundational knowledge of working with basic JSON structures, utilizing promises and the fetch() function in JavaScript. Additionally, we gained practical experience in manipulating the Date() object.',
    techs: ['HTML5', 'CSS3', 'JavaScript', 'Mob-programming'],
    githubLink: 'https://github.com/sammyolsson/project-weather-app',
    netlifyLink: 'https://scintillating-tanuki-2a80a2.netlify.app'
  },
  {
    id: '29ac7bce-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/vHz6LXRh/Guess-Who-v2.png',
    title: 'Guess what? - game',
    description: 'A digital version of the old classic game "Guess-Who?". Using array methods such as filter, forEach and mapping. The project set out to teach us to use JavaScript scope, objects and arrays.',
    techs: ['HTML', 'CSS', 'JavaScript'],
    githubLink: 'https://github.com/JohLeo/project-guess-who',
    netlifyLink: 'https://poetic-cobbler-eaa36b.netlify.app'
  },
  {
    id: '29ac7f20-3dda-11ee-be56-0242ac120002',
    imgSrc: 'https://i.postimg.cc/jSSn8Ntb/Chatbot-v1.png',
    title: 'Chatbot',
    description: 'The first JavaScript project in Technigos frontend developer bootcamp. Created to understand the fundamentals of JS. Built by using variables, functions, control flow and conditional statements/ternary operators.',
    techs: ['HTML5', 'CSS3', 'JavaScript'],
    githubLink: 'https://github.com/JohLeo/project-chatbot',
    netlifyLink: 'https://flourishing-begonia-4f3673.netlify.app'
  }
];